<template>
  <v-row>
    <v-col cols="4">
      <v-select
        v-model="phoneCode"
        :items="indicatifCodes"
        return-object
      />
    </v-col>
    <v-col cols="7">
      <v-text-field
        v-model="phoneNumber"
        :label="phoneLabel"
        :mask="phoneMask"
        :required="isRequired"
        :maxlength="numberLength"
        :outlined="true"
        dense
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch } from "vue";
import { useI18n } from "vue-i18n";

// Props avec valeurs par défaut
const props = withDefaults(defineProps<{
  label?: string;
  required?: boolean;
  phoneNumber?: string | number;
  dense?: boolean;
}>(), {
  label: "",
  required: false,
  phoneNumber: "",
  dense: false,
});

// Gestion des traductions
const { t } = useI18n();

// État réactif
const phoneCode = ref("+33"); // Code par défaut
const phoneNumber = ref(props.phoneNumber?.toString() || "");
const indicatifCurrent = reactive({ country: "FR", code: "+33", nb: 14 });

// Options des indicatifs
const indicatifOptions = [
  { country: "FR", code: "+33", nb: 14 },
  { country: "REU", code: "+262", nb: 14 },
  { country: "BEL", code: "+32", nb: 12 },
  { country: "CH", code: "+41", nb: 12 },
  { country: "EN", code: "+44", nb: 14 },
  { country: "IT", code: "+39", nb: 11 },
  { country: "LUX", code: "+352", nb: 10 },
  { country: "OTHER", code: "Other", nb: 11 },
];

// Variables calculées
const phoneLabel = computed(() => props.label || t("ui.basePhone.phone.false"));
const isRequired = computed(() => props.required || phoneCode.value !== "+33");
const indicatifCodes = computed(() => indicatifOptions.map((opt) => opt.code));
const numberLength = computed(() => indicatifCurrent.nb);
const phoneMask = computed(() => {
  if (isFrOrReu() && startsWithZero()) return "## ## ## ## ##";
  if (isFrOrReu() && !startsWithZero()) return "# ## ## ## ##";
  if (phoneCode.value === "+352") return "## ## ####";
  if (["+32", "+41"].includes(phoneCode.value)) return "## ### ## ##";
  return Array(indicatifCurrent.nb).fill("#").join("");
});

// Helpers
const startsWithZero = () => phoneNumber.value.startsWith("0");
const isFrOrReu = () => ["+33", "+262", "+44"].includes(phoneCode.value);

const updateIndicatif = () => {
  const match = indicatifOptions.find(opt => phoneCode.value === opt.code);
  if (match) Object.assign(indicatifCurrent, match);
};

// Gestion des événements
const emit = defineEmits(["change"]);
const emitChange = (phoneNumber: string) => {
  emit("change", phoneNumber.startsWith("+") ? phoneNumber : `${phoneCode.value}${phoneNumber}`);
};

// Watchers
watch(phoneCode, () => {
  updateIndicatif();
});

watch(phoneNumber, (newValue) => {
  const cleanedNumber = newValue.replace(/\s/g, "");
  emitChange(cleanedNumber);
});

// Initialisation
updateIndicatif();
</script>
